export const descriptions = {
    gg: `Grub Goblin is one of two capstone projects made during my time at Prime Digital Academy.  Grub Goblin was made start to finish in two weeks in February 2021.  The app is a weekly meal planning app with an emphasis on nutrition.
    
    A user can enter their own recipes into Grub Goblin, or import a recipe from a url.  The import feature was built with Puppeteer and targets a recipe's website's 'ld+json' metatag and extracts all recipe information from it.  After a recipe is entered it is stored in a database and the ingredients are sent to a 3rd party api for nutrition analysis.  The response is stored in the database and the recipe is ready for use.  Editing a recipe will recalculate its nutrition, as long as an ingredient was add, subtracted or edited.  The api will not be accessed if description, title or image are changed.
    
    Once a user has recipes they are able to drag and drop the recipes onto the weekly schedule.  All meals have their nutrition information totaled and displayed under the day.  The goal of this project was to create a weekly meal planning app where daily nutrition and weekly nutrition was easily accessed at a glance.
    
    Click the YouTube link below to view a demonstration of the app.`,
    saga: `SAGA Movie Database is an app that displays a list of movies. The movies can be clicked on and the user will be taken to a details page, with a description, movie poster, the genres the movie falls into and title. There is an add movie button on the header than can be clicked to add a movie to the database.
    
    For the previous two projects I've used Material-UI to style the page. For this project I decided to rely solely on my vanilla css skills. The movie list page is styled in an overlapping cards carousel. Hover over the cards will reveal more of the card and show the title of the movie layed over the image of the poster. The add movie page selects a random background image from a movie. I am happy with the way the app looks, but I would definitely like to tweak a couple things.`,
    gallery: `I built a photo album using React. This is my first attempt creating something with React and I was pleased with how well it went. The styling is a combination of vanilla css and components from Material UI. The first thing I did when I started the project was implement a database. The project was setup to store data on the server but I wanted to go the extra mile and make the data more permanent. I rewrote the get and put routes and implemented PG to talk to the database.

    I wanted to make sure that the project looked appealing but wasn't overly styled as to take attention away from the photos. The photos are sorted by their id from the database and then by their orientation. Portrait photos appear first and landscape photos second. If a user clicks on the photo it will reveal a description of the photo. A like button is located on the lower left corner of the photo and when pressed increments a like counter on the right. The amount of likes a picture has is stored in the database. A expand photo button is placed to the right of the counter. Clicking on this will display a larger more full version of the photo. Clicking anywhere on the DOM will close the larger photo. `,
    td: `A todo list with database implementation. The interface allows a user to add tasks to be displayed on the DOM. A user will enter their task, a category under which to file the task and a date the task should be accomplished by. All tasks are displayed on the right side of the DOM. Tasks are ordered by their status, uncompleted tasks will always appear above completed. An icon on the left side of the task shows what category the task belongs to. The task is displayed in the middle of the task container, the date it was added and the target date of completion are listed underneath. On the right side of the task are buttons to mark task completed, edit the task and delete it.

    Editing the task will empty out the task container and allow the user to edit that task. Marking task complete will change the color from blue to pink and move it to the bottom of the task stack. The word completed will also appear on the left side of the task card. Only one task can be edited at a time. Confirming or canceling changes will allow the user to edit another.
    
    All categories will be displayed as tags underneath the task input field. The user has the ability to create and delete custom tags here.`,
    calc: `Built a functional basic calculator where all math is done on the server and sent back to the dom through AJAX gets and posts. I quickly built out the base function of the calculator and went on to making it a little more complicated. Calculator can do all things you'd expect a basic calculator to do, addition, subtraction, division, and multiplication.

    After basic functionality was built and tested I moved on to a few more complicated tasks. Adding a plus/minus toggle button, a square root function, and a percent conversion button. Pressing the c/ch once will clear the numbers on screen. If you are at zero pressing it again will clear the calculation history on the right of the calculator. In addition to those functions, I added a fully functional memory function like you'd find on a scientific calculator. I had to read about how those systems work and do a little experimenting but its in there and does what it's supposed to do. Press the M+ or M- after entering a number and a positive or negative version of that number will be stored in the server. You can add and subtract to that number in the same manner. Pressing the MRC button once will recall the memory number. If the number on screen is the number is the number in memory pressing the MRC button again will reset that memory value back to 0 on the server.`
,
    snowSwaps: `SnowSwaps is a mobile-friendly online marketplace for members of snow-loving communities to buy, sell, or browse used snow-sport equipment. SnowSwaps bridges the gap between buyers and sellers of used snow sport equipment on a single platform. Members of the App can request permission from SnowSwaps to gain the ability to create virtual “swaps” where users can browse items listed in the swap. Swaps can be public, or they can be private requiring a passcode to interact with the swap. Buyers can contact sellers to negotiate price and a location to meet.
    
    The app was created as a capstone group project at the end of my time at Prime Digital Academy.  The project is a proof of concept for a real world client, competitive snowboard racer, Zach Lulavy.  I collaborated with three other devs on the project.  The project was completed in three weeks, the first week was spent planning and making wire frames the second two were used to develop the app`
};
